

(function accordeonListSeo () {
    const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
    const compName = "accordeon-list-seo";
    const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
    const Log = logFactory(`${compName}.js`);

      if (!isCompExists(compName, Log)) {
       return;
     }

      $(() => {
        let isMobile = false;

        const setSize = () => {
            let width = document.body.clientWidth;

            if ((width < 768) && !isMobile) {
                isMobile = true;
                setOpen(width);
            } else if ((width >= 768) && isMobile)  {
                isMobile = false;
                setOpen(width);
            }
        }

        const setOpen = (width) => {
            if (width) {
                const list = document.querySelectorAll(".accordion_container__list");

                    list.forEach(el => {
                    const items = el.querySelectorAll("li > .accordion");  

                        items.forEach(item => {
                        if (width < 768) {
                            item.removeAttribute("open");
                        } else if (width >= 768) {
                            item.setAttribute("open", "");
                        }
                    });
                });
            }
        }

        const onDomContentLoaded = () => {
            setSize();
            setOpen();
        }

        window.addEventListener("resize", setSize);
        window.addEventListener("DOMContentLoaded", onDomContentLoaded());
     })
 })()