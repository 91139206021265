(function stickyButton () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "sticky-button";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    $(() => {
      const widgetSimuButton = document.querySelector('#widget-simu-button');
      const stickyButton = document.querySelector('.sticky-button');

      let isMobile = window.matchMedia("(max-width: 830px)");
      let observer;

      const displayStickyButton = (element) => {
        element.style.display = 'flex';
        element.style.opacity = '1';
      }

      const watchObserverAndModifyButtonStyle = (entries) => {
        entries.forEach(entry => {
          if (!isMobile.matches) {
            if (entry.isIntersecting) {
              stickyButton.style.opacity = '0';
              setTimeout(() => {
                  stickyButton.style.display = 'none';
              }, 300);
            } else {
              stickyButton.style.display = 'flex';
              setTimeout(() => {
                  stickyButton.style.opacity = '1';
              }, 300);
            } 
          } else {
            displayStickyButton(stickyButton);
          }
        }); 
      }

      const observeWidgetButton = () => {
        observer = new IntersectionObserver(watchObserverAndModifyButtonStyle);
        widgetSimuButton ? observer.observe(widgetSimuButton) : displayStickyButton(stickyButton);
      }

      observeWidgetButton();

      isMobile.addEventListener('change', observeWidgetButton)
    })
})()