

(function tableCr () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "table-cr";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    $(async () => {
      let isMobile = window.matchMedia("(max-width: 768px)");
      let isMounted = false;

      const splide = new Splide('.rowgroup_carousel', {
        perPage: 1,
        perMove: 1,
        easing: 'ease-out',
        classes: {
          prev: 'i-arrow-left-short',
          next: 'i-arrow-right-short'
        },
        i18n: {
          prev: "Colonne précédente",
          next: "Colonne suivante",
          slideX: "Aller à la colonne %s",
          carousel: "tableau",
          slide: "colonne",
          select: "Sélectionner une colonne à afficher",
          slideLabel: "%s sur %s"
        }
      });

      splide.on('mounted', () => {
        const arrowsContainer = document.querySelector('.splide__arrows');
        const paginationContainer = document.querySelector('.splide__pagination');
        arrowsContainer.setAttribute('aria-hidden', 'true');
        paginationContainer.setAttribute('aria-hidden', 'true');
      })

      splide.on('pagination:updated', (data) => {
        data.items.forEach((item, index) => {
          let perPage = 1;
          let start = index * perPage + 1;
          let end = Math.min(start + perPage - 1, splide.length);

          item.button.ariaLabel = `Afficher les avis clients ${start} à ${end}`;
          item.button.ariaCurrent = item.button.ariaSelected;
        });
      });

            const handleSplideCarouselForTables = () => {
          if(isMobile.matches && !isMounted) {
            $('.sr-only_container').removeClass('hide-table').addClass('sr-only');
            splide.mount();
            isMounted = true;
          } else if (!isMobile.matches && isMounted) {
            $('.sr-only_container').addClass('hide-table').removeClass('sr-only');
            splide.destroy();
            isMounted = false;
          }
      }

      isMobile.addEventListener('change', handleSplideCarouselForTables);


            const URL_FORMULAS_EMI = `/fr/credit.formulasList.json?vendorCode=${window?.vendorDFRANo}&productType=EMI`;

      const formattedNumber = (number, decimal = 2) => {
        const formattedNumber = new Intl.NumberFormat('fr-FR', {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal,
        }).format(number);

            if (Number.isInteger(number)) {
          return formattedNumber.replace(/,00$/, '');
        }

                return formattedNumber;
      };

      const getData = async (PATH) => {
        try {
          const response = await fetch(PATH);
          return response.status === 200 ? response.json() : Promise.resolve([]);
        } catch (error) {
          return Promise.resolve([]);
        }
      };

      const reformatData = (data) => {
        const result = [];

              data.forEach(item => {
          if (item.minDuration === item.maxDuration) {
            item.minDuration = 0;
          }

          let group = result.find(group => group.minAmount === item.minAmount && group.maxAmount === item.maxAmount);

          if (!group) {
            group = {
              minAmount: item.minAmount,
              maxAmount: item.maxAmount,
              data: []
            };
            result.push(group);
          }

          group.data.push({
            minDuration: item.minDuration,
            maxDuration: item.maxDuration,
            taeg: item.taeg,
            tnc: item.tnc
          });
        });

        result.forEach(group => {
          const allDurations = [];

          group.data.forEach(entry => {
            const key = `${entry.minDuration}-${entry.maxDuration}`;
            allDurations.push(key);
          });

          result.forEach(otherGroup => {
            if (group.minAmount !== otherGroup.minAmount || group.maxAmount !== otherGroup.maxAmount) {
              otherGroup.data.forEach(entry => {
                const key = `${entry.minDuration}-${entry.maxDuration}`;

                if (!allDurations.includes(key)) {
                  const lastEntry = group.data[group.data.length - 1];

                  group.data.push({
                    minDuration: entry.minDuration,
                    maxDuration: entry.maxDuration,
                    taeg: lastEntry.taeg,
                    tnc: lastEntry.tnc
                  });

                  allDurations.push(key);
                }
              });
            }
          });
        });

              return result;
      }

      const getFormulaList = async () => {
        const formulaAPI = await getData(URL_FORMULAS_EMI);
        if(!formulaAPI || formulaAPI.length === 0) return [];

        return formulaAPI?.emiFormulas;
      };

      const setDataTables = (dataTable) => {
        document.querySelector("#headDesktop").insertAdjacentHTML('afterbegin', `
            <th 
              id="first-cell"
              class="table_container__element-fixedhead title-legend"
              scope="col"
            >
              Durée
            </th>
            
            ${dataTable.map(item => `
                <th 
                  class="table_container__element-head"
                  scope="col"
                >
                  ${formattedNumber(item.minAmount)} - ${formattedNumber(item.maxAmount)} €
                </th>
              `
            ).join('')}
          `
        )

        document.querySelector('#bodyDesktop').insertAdjacentHTML('afterbegin', 
          dataTable[0].data.map((item, index) => `
              <tr>
                <th 
                  class="table_container__element-fixedhead fixed-legend"
                  scope="row"
                >
                  <span aria-hidden="true">
                    ${item.minDuration} - ${item.maxDuration} mois / max
                  </span>
                  <span class="sr-only">
                    test2
                    ${item.minDuration} à ${item.maxDuration} mois au maximum
                  </span>
                </th>
                <td class="table_container__element-data">
                  ${formattedNumber(item.taeg)} %
                </td>
                <td class="table_container__element-data">
                  ${formattedNumber(dataTable[1].data[index].taeg)} %
                </td>
              </tr>
            `
          ).join('')
        )

        document.querySelector("#durationMobile").insertAdjacentHTML('afterbegin', `
            <div class="table_container__element-fixedhead title-legend">
              Durée
            </div>
            ${dataTable[0].data.map((item) => `
                <div id="mobile" class="table_container__element-fixedhead fixed-legend">
                  ${item.minDuration} - ${item.maxDuration} mois / max
                </div>
              `
            ).join('')}
          `
        )

        dataTable.map((item) => 
          document.querySelector("#dataMobile").insertAdjacentHTML('beforeend', `
              <li class="rowgroup_carousel__col splide__slide">
                <div class="rowgroup_carousel__col-row">
                  <div class="table_container__element-head">
                    ${formattedNumber(item.minAmount)} - ${formattedNumber(item.maxAmount)} €
                  </div>
                  ${item.data.map((data) => `
                      <div class="table_container__element-data">
                        ${formattedNumber(data.taeg)} %
                      </div>
                    `
                  ).join('')}
                </div>
              </li>
            `
          )
        ).join('')
      }

            const onDomContentLoaded = async () => {
        const data = await getFormulaList();

              if (data.length === 0) {
          Log.log({ message: 'error load formulas list' });
          return;
        }

        const formattedData = reformatData(data);

        setDataTables(formattedData);
        handleSplideCarouselForTables();
      }

      onDomContentLoaded();
    })
})()